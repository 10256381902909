<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-ap-white-smoke tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{ '!tw-flex': modelValue == true }"
    >
      <!-- Modal Content -->
      <div class="tw-max-w-[343px] tw-w-full sgTablet:tw-max-w-[640px] tw-mx-4">
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group" @click="handleModalClose">
            <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
              {{ $t('modal_close') }}
            </p>
            <img :src="$cdn('icons/cancel_icon.svg')" alt="cancel_icon" />
          </div>
        </div>

        <!-- LightBox -->
        <div
          class="tw-bg-white tw-rounded-lg tw-w-full tw-shadow-ap-preset-1 tw-flex tw-flex-col tw-relative tw-max-h-[80vh] tw-h-full tw-pb-5 tw-overflow-hidden"
        >
          <!-- Image Box -->
          <div class="tw-relative tw-pt-[56.25%]">
            <template v-if="data.is_video_embed === false">
              <img class="tw-object-cover tw-h-full tw-w-full tw-object-center tw-absolute tw-top-0" :src="data.image" />
              <div v-if="data.is_past" class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center">
                <div class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white">
                  {{ $t('cny_whats_open_past_event') }}
                </div>
              </div>
            </template>
            <iframe
              v-else
              class="tw-object-cover tw-h-full tw-w-full tw-object-center tw-absolute tw-top-0"
              :src="data.video_link"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>

          <div class="tw-overflow-y-auto tw-overscroll-none">
            <div class="tw-p-5">
              <div
                class="tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-mt-3"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ data.title }}
              </div>
            </div>
            <ap-wysiwyg-viewer class="tw-mt-4 tw-px-5" :data="data.description" />
          </div>
        </div>

        <div v-if="!!data.external_link" class="tw-w-full tw-flex tw-flex-row tw-justify-center tw-mt-5">
          <a
            target="_blank"
            :href="data.external_link"
            class="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-[#C52600] tw-h-[52px] tw-w-[251px] tw-text-white tw-gap-x-2"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            <div>
              {{ $t('cny_whats_open_find_out_more') }}
            </div>
            <inline-svg :src="$cdn('icons/arrow_outward.svg')" />
          </a>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { onMounted, computed, ref } from 'vue';
import { checkLang } from '../../../support/helper';

type Post = {
  title: string;
  description: string;
  image: string;
  external_link: string;
  is_video_embed: boolean;
  video_link: string;
  is_past?: boolean;
};

const props = defineProps<{
  modelValue: boolean;
  data: Post;
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if (!newVal) {
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  } else {
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
  }
});
</script>
