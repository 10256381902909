<script lang="ts" setup>
import { onMounted, watch } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../../support/cdn';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../support/helper';

const { t } = useI18n();
const currentQuestionNo = ref(0);
const totalQuestionNo = ref(4);
const questions = ref({
  1: {
    title_en: 'What is your favourite type of mooncake?',
    title_cn: '你最喜欢的月饼是什么？',
  },
  2: {
    title_en: 'What’s your go-to order when you’re craving tea?',
    title_cn: '你想喝茶时，通常会想点什么？',
  },
  3: {
    title_en: 'Choose your favourite SINGAPO人 food from this menu of items',
    title_cn: '你最喜欢的新加坡美食是什么？',
  },
  4: {
    title_en: 'Which of these statements best describe you? During a gathering, I’m...',
    title_cn: '以下哪个句子最符合你的性格？有聚会时，我通常是...',
  },
});
const options = ref({
  1: {
    'hou-yi': {
      option_en: 'Traditional lotus paste',
      option_cn: '传统莲蓉馅月饼',
    },
    'chang-e': {
      option_en: 'Snowskin',
      option_cn: '冰皮月饼',
    },
    'wu-gang': {
      option_en: 'Teochew style',
      option_cn: '潮式月饼',
    },
    'jade-rabbit': {
      option_en: 'Any trendy flavour!',
      option_cn: '任何新潮月饼!',
    },
  },
  2: {
    'hou-yi': {
      option_en: 'Traditional Chinese tea',
      option_cn: '传统中国茶',
    },
    'chang-e': {
      option_en: 'Bubble tea all the way!',
      option_cn: '珍珠奶茶，非它不可！',
    },
    'wu-gang': {
      option_en: 'Ice lemon tea',
      option_cn: '冰柠檬茶',
    },
    'jade-rabbit': {
      option_en: 'Packet or canned tea',
      option_cn: '袋装茶或罐装茶',
    },
  },
  3: {
    'hou-yi': {
      option_en: 'Laksa',
      option_cn: '叻沙',
    },
    'chang-e': {
      option_en: 'Kaya toast and eggs',
      option_cn: '咖椰吐司和半熟蛋',
    },
    'wu-gang': {
      option_en: 'Yong tau foo',
      option_cn: '酿豆腐',
    },
    'jade-rabbit': {
      option_en: 'Chicken rice',
      option_cn: '海南鸡饭',
    },
  },
  4: {
    'hou-yi': {
      option_en: 'The early bird',
      option_cn: '最早抵达',
    },
    'chang-e': {
      option_en: 'Chronically late',
      option_cn: '总是迟到的',
    },
    'wu-gang': {
      option_en: 'Usually there just in time',
      option_cn: '准时到达的',
    },
    'jade-rabbit': {
      option_en: 'Usually absent and don’t show up',
      option_cn: '经常爽约',
    },
  },
});
const answers = ref<string[]>([]);
const results = ref({
  'hou-yi': {
    title_en: 'Hou Yi',
    title_cn: '后羿',
    desc_en: 'A go-getter who will stop at nothing to achieve your goals.',
    desc_cn: '你是行动派，未达到目的前绝不罢休。',
    image: cdn('images/ma2024/personality-quiz/ma_personality_quiz_result_01.v2.jpg'),
  },
  'chang-e': {
    title_en: 'Chang’e',
    title_cn: '嫦娥',
    desc_en: 'You’re always shooting for the moon. Even if you miss, you’ll land among the stars!',
    desc_cn: '你总是志存高远，即使错过了月亮，你还会落在群星之中。',
    image: cdn('images/ma2024/personality-quiz/ma_personality_quiz_result_02.v2.jpg'),
  },
  'wu-gang': {
    title_en: 'Wu Gang',
    title_cn: '吴刚',
    desc_en:
      "Challenges are like branches to you – you just keep breaking them until they are removed. You're a real 'cut above' the rest when it comes to perseverance!",
    desc_cn: '挑战对你来说就像树枝一样——你会坚持到底把它们拗断，将它们完全去除。在坚持不懈方面，你绝对"高人一等"',
    image: cdn('images/ma2024/personality-quiz/ma_personality_quiz_result_03.v2.jpg'),
  },
  'jade-rabbit': {
    title_en: 'Rabbit',
    title_cn: '玉兔',
    desc_en: 'You always put others before yourself – and it’s not going unnoticed!',
    desc_cn: '你总是先考虑别人，而这一点他们也注意得到！',
    image: cdn('images/ma2024/personality-quiz/ma_personality_quiz_result_04.v2.jpg'),
  },
});
const result = ref<{ title_en: string; title_cn: string; desc_en: string; desc_cn: string; image: string }>({
  title_en: '',
  title_cn: '',
  desc_en: '',
  desc_cn: '',
  image: '',
});
const currentQuestion = ref<{ title_en: string; title_cn: string }>({ title_en: '', title_cn: '' });
const currentOptions = ref<[string, { option_en: string; option_cn: string }][]>([]);
const showQuestion = ref(false);
const showResult = ref(false);
const showCover = ref(true);

const validateAnswer = (answer: string) => {
  answers.value.push(answer);
  if (currentQuestionNo.value == totalQuestionNo.value) {
    const calc = calculateAnswers();
    result.value = results.value[calc[0]];
    showResult.value = true;

    //showQuestion.value = false;
    return;
  } else {
    currentQuestionNo.value++;
    currentQuestion.value = questions.value[currentQuestionNo.value];
    if (currentQuestionNo.value - 1 == totalQuestionNo.value - 1) {
      const calc = calculateAnswers();
      if (calc.length == 2) {
        let tempOptions = [];
        calc.forEach((c) => {
          tempOptions.push([c, options.value[currentQuestionNo.value][c]]);
        });
        currentOptions.value = tempOptions;
      } else {
        currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
      }
    } else {
      currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
    }
    return;
  }
};

let scrollPosition = 0;

const showModal = () => {
  scrollPosition = window.pageYOffset;
  document.body.classList.add('no-scroll');
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.position = 'fixed';
};

const hideModal = () => {
  document.body.classList.remove('no-scroll');
  document.body.style.top = '';
  document.body.style.position = '';
  window.scrollTo(0, scrollPosition);
};

watch(showResult, (newValue) => {
  if (newValue) {
    showModal();
  } else {
    hideModal();
  }
});

const restartQuiz = () => {
  showCover.value = true;
  showQuestion.value = false;
  showResult.value = false;
  answers.value = [];
  result.value = { title_en: '', title_cn: '', desc_en: '', desc_cn: '', image: '' };
  currentQuestionNo.value = 0;
};

const startQuiz = () => {
  restartQuiz();
  showCover.value = false;
  showQuestion.value = true;
  showResult.value = false;
  currentQuestionNo.value = 1;
  currentQuestion.value = questions.value[currentQuestionNo.value];
  currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
};

const calculateAnswers = () => {
  let highest = 1;
  let highestCategory: string[] = [];
  const counts = answers.value.reduce((counts: any, answer) => {
    if (counts[answer]) {
      counts[answer]++;
    } else {
      counts[answer] = 1;
    }

    if (counts[answer] > highest) {
      highest = counts[answer];
      highestCategory = [answer];
    } else if (counts[answer] == highest) {
      highestCategory.push(answer);
    }
    return counts;
  }, {});
  highestCategory = highestCategory.sort();
  return highestCategory;
};
</script>

<template>
  <section
    :class="[
      'tw-bg-sg-ma24-pink tw-relative',
      'tw-pt-[32px] sgTablet:tw-pt-[62px] sgTablet2:tw-pt-[74px] sgDesktop:tw-pt-[72px] sgDesktop2:tw-pt-[84px]',
      'tw-pb-10',
      'tw-px-4 sgTablet:tw-px-10 sgTablet2:tw-px-20',
      'tw-flex tw-flex-col tw-justify-center tw-items-center',
    ]"
  >
    <div :class="['tw-flex tw-items-center tw-justify-center', 'tw-w-full sgTablet2:tw-max-w-[800px]', 'sgTablet:tw-pb-[63px] tw-pb-[43px]']">
      <div
        :class="[
          'tw-text-center',
          'tw-text-sg-ma24-purple-b20',
          'tw-text-sg-ma-h3-mobile sgTablet:tw-text-sg-ma-h3-tablet sgDesktop:tw-text-sg-ma-h3-desktop',
          { 'tw-font-akkuratbold': checkLang() == 'en' },
          { 'tw-font-notosansbold': checkLang() != 'en' },
        ]"
      >
        {{ $t('ma_personality_quiz_section_1_title') }}
      </div>
    </div>

    <div :class="['tw-flex tw-justify-center tw-relative tw-items-end', 'tw-max-w-[880px] tw-w-full']">
      <img
        v-if="showQuestion"
        :class="['tw-absolute tw-bottom-0 tw-left-0', 'tw-w-[120px] tw-hidden sgTablet:tw-block']"
        :src="cdn('images/ma2024/personality-quiz/ma_personality_quiz_03.png')"
      />
      <div class="tw-flex tw-flex-col tw-items-center tw-w-full">
        <template v-if="showCover">
          <div class="tw-flex tw-justify-center tw-items-center sgTablet:tw-h-[280px]">
            <div>
              <button
                :class="[
                  // Base styles
                  'tw-h-[56px] tw-py-3 tw-px-7 tw-rounded-[56px] tw-relative tw-min-w-[200px] tw-bg-[#FDC404] tw-text-[#1A1A1A] tw-text-sg-ma-sh2 tw-cursor-pointer !tw-font-[400]',
                  // Hover styles
                  'hover:tw-bg-[#FDD036]',
                  // Active styles
                  'active:tw-bg-[#CA9D03] before:active:tw-border-t-[#CA9D03]',
                  // Disabled styles
                  'disabled:tw-bg-[#D1D1D1] disabled:tw-text-white disabled:tw-cursor-not-allowed',
                  // Hover pseudo-elements
                  'hover:before:tw-border-t-[#FDD036]',
                  // Disabled pseudo-elements
                  'before:disabled:tw-border-t-[#D1D1D1] before:tw-border-t-solid',
                  { 'tw-font-akkuratbold': checkLang() == 'en' },
                  { 'tw-font-notosansbold': checkLang() != 'en' },
                ]"
                class="before:tw-content-[''] before:tw-absolute before:tw-top-full before:tw-left-1/2 before:-tw-translate-x-1/2 before:tw-border-[10px] before:tw-border-transparent before:tw-border-t-[#FDC404]"
                @click="startQuiz"
              >
                {{ $t('cny_personality_quiz_lets_start') }}
              </button>

              <img class="tw-h-[200px]" :src="$cdn('images/ma2024/personality-quiz/ma_personality_quiz_01.png')" />
            </div>
          </div>
        </template>
        <template v-else-if="showQuestion">
          <!-- Question -->
          <div class="tw-flex tw-items-center tw-justify-center tw-max-w-[640px] tw-w-full tw-h-[84px]">
            <div
              :class="[
                'tw-text-center',
                'tw-text-sg-ma-sh2 tw-text-sg-ma24-purple-b40',
                { 'tw-font-akkuratbold': checkLang() == 'en' },
                { 'tw-font-notosansbold': checkLang() != 'en' },
              ]"
            >
              {{ checkLang() == 'en' ? currentQuestion.title_en : currentQuestion.title_cn }}
            </div>
          </div>
          <!-- Answer Options -->
          <div
            :class="[
              'tw-grid tw-grid-cols-2 tw-w-full',
              'tw-mt-5 sgTablet:tw-mt-7 sgDesktop:tw-mt-9',
              'tw-gap-4 sgTablet:tw-gap-5 sgDesktop:tw-gap-6',
              'tw-max-w-[640px]', // This makes the container grow to full width
            ]"
          >
            <template v-for="([optionKey, optionVal], index) in currentOptions" :key="index">
              <div
                :class="[
                  'tw-w-full',
                  'tw-flex tw-items-center tw-justify-center tw-col-span-2',
                  'tw-bg-[#FDD036] hover:tw-bg-[#FDC404] active:tw-bg-[#A27E02] disabled:tw-bg-[#D1D1D1]',
                  'tw-border-[#FDC404] hover:tw-border-[#CA9D03] active:tw-border-[#826502] disabled:tw-border-[#D1D1D1] tw-border-2',
                  'tw-text-sg-ma-sh2',
                  'tw-text-[#1A1A1A] active:tw-text-white disabled:tw-text-white',
                  'tw-rounded-lg tw-h-[76px] tw-text-center tw-py-2 tw-px-3 tw-cursor-pointer',
                  { 'tw-font-akkuratbold': checkLang() == 'en' },
                  { 'tw-font-notosansbold': checkLang() != 'en' },
                  currentOptions.length == 2 || (index + 1 == currentOptions.length && (index + 1) % 2 != 0)
                    ? 'sgTablet:tw-col-span-2'
                    : 'sgTablet:tw-col-span-1',
                ]"
                :style="{
                  boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.06),0px 0px 4px 0px rgba(0,0,0,0.04)',
                }"
                @click="validateAnswer(optionKey)"
              >
                <span v-html="checkLang() == 'en' ? optionVal.option_en : optionVal.option_cn" />
              </div>
            </template>
          </div>
        </template>

        <!-- Stepper pagination -->
        <ol
          class="tw-flex tw-items-center tw-w-[min(calc(100vw-200px-24px),260px)] sgTablet:tw-w-[260px] tw-mx-auto tw-mt-9 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-[68px]"
        >
          <li
            v-for="i in totalQuestionNo - 1"
            :key="i"
            class="tw-flex tw-w-full tw-items-center after:tw-content-[''] after:tw-w-full after:tw-h-[2px] after:tw-border-b after:tw-border-[#623A7E] after:tw-border-2 after:tw-inline-block"
          >
            <span
              class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-[#623A7E]"
              :class="[i <= currentQuestionNo ? 'tw-bg-[#623A7E]' : 'tw-bg-transparent']"
            />
          </li>

          <li class="tw-flex tw-items-center">
            <span
              class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-[#623A7E]"
              :class="[currentQuestionNo === totalQuestionNo ? 'tw-bg-[#623A7E]' : 'tw-bg-transparent']"
            />
          </li>
        </ol>

        <div class="tw-flex tw-items-center tw-justify-center tw-mt-10 sgTablet:tw-mt-[60px]" :class="[showCover ? 'tw-text-[#A3A3A3]' : 'tw-text-[#1A1A1A']">
          <div
            class="tw-py-2 tw-px-4 tw-flex tw-items-center tw-z-[100] tw-text-sg-ma-bt1"
            :class="[
              { 'tw-font-akkuratbold': checkLang() == 'en' },
              { 'tw-font-notosansbold': checkLang() != 'en' },
              { 'tw-group/cny-personality-quiz-snack-restart-btn tw-cursor-pointer': !showCover },
            ]"
            @click="!showCover ? startQuiz() : null"
          >
            <span :class="['group-hover/cny-personality-quiz-snack-restart-btn:tw-underline']"> {{ $t('cny_personality_quiz_restart') }}</span>
            <inline-svg :fill="showCover ? '#A3A3A3' : '#1A1A1A'" :src="$cdn(`/icons/restart_alt.svg`)" class="tw-w-6 tw-h-6 tw-ml-2" />
          </div>
        </div>
      </div>
      <img
        v-if="showQuestion"
        :class="['tw-absolute tw-bottom-0 tw-right-0', 'tw-w-[120px] tw-hidden sgTablet:tw-block']"
        :src="cdn('images/ma2024/personality-quiz/ma_personality_quiz_02.png')"
      />
    </div>

    <div class="tw-absolute tw-top-0 tw-w-full">
      <ma-wave-break-line image="images/ma2024/whats-open/ma_line_break.svg" direction="" />
    </div>

    <ma-personality-quiz-result-modal v-model="showResult" :image="result?.image" @on-hide="restartQuiz" />
  </section>
</template>

<style scoped>
body.no-scroll {
  overflow: hidden;
}
</style>
