// @ts-expect-error It will have
import { createApp, ref } from 'vue/dist/vue.esm-bundler';
import 'swiper/css/bundle';
import '../styles/main.scss';

import Components from './components';
import Pages from './pages';
import InlineSvg from 'vue-inline-svg';
import { createI18n } from 'vue-i18n';
import messages from './i18n';

import { cdn } from './support/cdn';
import { langRedirect } from './support/langRedirect';
import * as Sentry from '@sentry/vue';


//#region i18n Related setup
// TODO: bruh, don't export anything from main.ts
export type MessageLanguages = keyof typeof messages;
// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = typeof messages['en-US'];

// TODO: can move this to d.ts
// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}

  // define the datetime format schema
  export interface DefineDateTimeFormat {}

  // define the number format schema
  export interface DefineNumberFormat {}
}

// Moved to global-functions.d.ts
// declare module '@vue/runtime-core' {
//   interface ComponentCustomProperties {
//     $cdn: (filePath: string, preset?: string, useLocal?: boolean) => string;
//     $langRedirect: (path: string, external?: string) => string;
//   }
// }


const i18n = createI18n({
  warnHtmlMessage: false,
  legacy: false,
  locale: 'en-US', // set locale
  fallbackLocale: 'en-US', // set fallback locale
  messages
  // If you need to specify other options, you can set other options
  // ...
});
//#endregion


// Vue initialization
const vueApp = createApp({
  setup() {
    const wordpressData = ref(window?.['__wordpress_data__']);

    return {
      wordpressData
    };
  }
});

// Component installation
Object.entries(Components).forEach((component) => {
  // console.log('Globally installed component: ', component[0], component[1]);
  vueApp.component(component[0], component[1]);
});

vueApp.component('inline-svg', InlineSvg);

// Page installation
Object.entries(Pages).forEach((component) => {
  // console.log('Globally installed page: ', component[0], component[1]);
  vueApp.component(component[0], component[1]);
});

// CDN Usage
vueApp.config.globalProperties.$cdn = cdn;
// i18n Usage
vueApp.use(i18n);
// lang Redirect Usage
vueApp.config.globalProperties.$langRedirect = langRedirect;
// Can install other components, plugins over here
// ...



Sentry.init({
  enabled: ['prod', 'staging'].includes(process.env.APP_ENV as string),
  app:vueApp,
  dsn: process.env.SENTRY_DSN,
  environment: process.env.APP_ENV,
  integrations: [
    Sentry.replayIntegration({
      maskAllText:false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event: any, hint: any) => {
    if (process.env.APP_ENV == 'local') {
      console.error(event);
      console.error(hint.originalException || hint.syntheticException);
      return null; // this drops the event and nothing will be send to sentry
    }

    return event;
  },
  ignoreErrors:[
    'Jsloader error (code #1):', // Google api script, not using, suspect from plugin
    'YT is not defined', //videojs-youtube not using
    '{}',
    'HupuBridge',// hupu https://www.hupu.com/ is a site for china to watch nba, suspecting some app or plugin is referencing it.
    'window.Frodo.Webview', //another webview thingy
    'X is not a function', // google bot crawling
    '<unknown>', // google bot crawling
    'getPercent is not defined',  // google bot crawling
    'i.replace is not a function', // google bot crawling
    'a.L', // suspect from wp plugin/webview
  ]
});

vueApp.mount('#vue-app');
