<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import { useI18n } from 'vue-i18n';
import { h, ref } from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData: any;
}>();
const { t } = useI18n();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');
const menuItems = [
  {
    label: 'dw_about_menu_cantonese',
    id: 'cantonese-section',
  },
  {
    label: 'dw_about_menu_hokkien',
    id: 'hokkien-section',
  },
  {
    label: 'dw_about_menu_hainanese',
    id: 'hainanese-section',
  },
  {
    label: 'dw_about_menu_teochew',
    id: 'teochew-section',
  },
  {
    label: 'dw_about_menu_nyonya',
    id: 'nyonya-section',
  },
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onBeforeMount(() => {
  if (props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if (supElements?.length > 0) {
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if (supElements?.length > 0) {
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :dw-navigation="true"
    />
    <dw-secondary-navigation :menu-items-data="menuItems" />
    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/dw2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-sg-h1-mobile-dw-about-title-zh sgTablet:tw-text-sg-h1-tablet-dw-about-title-zh sgDesktop:tw-text-sg-h1-dw-about-title-zh tw-pb-1 tw-font-notosansbold'
                : 'tw-text-sg-h1-mobile-dw-about-title sgTablet:tw-text-sg-h1-tablet-dw-about-title sgDesktop:tw-text-sg-h1-dw-about-title tw-pb-1  tw-font-akkuratbold'
            "
          >
            {{ $t('dw_about_title') }}
          </div>

          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-sg-sh1-mobile-dw-zh sgTablet:tw-text-sg-sh1-tablet-dw-zh sgDesktop:tw-text-sg-sh1-dw-zh tw-pb-1 tw-font-notosansbold'
                : 'tw-text-sg-sh1-mobile-dw sgTablet:tw-text-sg-sh1-tablet-dw sgDesktop:tw-text-sg-sh1-dw tw-mt-1 tw-font-akkuratbold'
            "
          >
            {{ $t('dw_about_subtitle') }}
          </div>
        </div>
      </div>
    </div>

    <section class="tw-px-4 sgTablet:tw-px-10 tw-pt-5 sgTablet:tw-pt-10 tw-pb-[60px] sgDesktop:tw-pb-[100px]">
      <div
        id="sup-handler-container"
        class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider [&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]"
        v-html="wordpressData.description"
      />
    </section>

    <section
      class="tw-px-4 sgTablet:tw-px-10 tw-pt-[60px] sgDesktop:tw-pt-[100px] tw-pb-10 tw-bg-sg-dw24-blue-w60 tw-relative"
      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
    >
      <img
        :src="$cdn('images/dw2024/about/left.png')"
        class="tw-absolute tw-left-0 tw-h-[120px] tw-w-[120px] tw-top-[-60px] sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >
      <img
        :src="$cdn('images/dw2024/about/right.png')"
        class="tw-absolute tw-right-0 tw-h-[120px] tw-w-[120px] tw-top-[-60px] sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >

      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-space-y-6">
        <div
          :class="
            checkLang() == 'zh-hans'
              ? 'tw-text-center  tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold'
              : 'tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'
          "
        >
          {{ $t('dw_about_dumplings_title') }}
        </div>
        <div class="tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider">
          {{ $t('dw_about_dumplings_desc') }}
          <span>
            <sup
              class="tw-text-[#0366D8] tw-cursor-pointer"
              @click="handleOpenImageSourceModal"
            >[1]</sup>
          </span>
        </div>
        <div
          class="tw-text-xs tw-tracking-wider"
          v-html="$t('dw_about_dumplings_caption')"
        />
      </div>
    </section>

    <section
      id="cantonese-section"
      class="tw-px-4 sgTablet:tw-px-10 tw-py-5 sgTablet:tw-py-10 tw-bg-sg-dw24-blue-w40"
    >
      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-5 sgTablet:tw-gap-10">
        <div class="sgTablet:tw-order-2 tw-flex tw-place-self-center">
          <img
            :src="$cdn('images/dw2024/about/cantonese.png')"
            class="tw-h-full tw-object-contain tw-w-[100px] sgTablet:tw-w-[200px] tw-max-w-[100px] sgTablet:tw-max-w-[200px]"
          >
        </div>
        <div class="tw-space-y-5 tw-flex-1 tw-flex tw-flex-col tw-place-self-center">
          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-center  sgTablet:tw-text-left tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold'
                : 'tw-text-center sgTablet:tw-text-left tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'
            "
          >
            {{ $t('dw_about_cantonese_title') }}
          </div>
          <div class="tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider">
            {{ $t('dw_about_cantonese_desc') }}
          </div>
        </div>
      </div>
    </section>

    <section
      id="hokkien-section"
      class="tw-px-4 sgTablet:tw-px-10 tw-py-5 sgTablet:tw-py-10 tw-bg-sg-dw24-blue-w20"
    >
      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-5 sgTablet:tw-gap-10">
        <div class="tw-flex tw-place-self-center">
          <img
            :src="$cdn('images/dw2024/about/hokkien.png')"
            class="tw-h-full tw-object-contain tw-w-[100px] sgTablet:tw-w-[200px] tw-max-w-[100px] sgTablet:tw-max-w-[200px]"
          >
        </div>
        <div class="tw-space-y-5 tw-flex-1 tw-flex tw-flex-col tw-place-self-center">
          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-center  sgTablet:tw-text-left tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold'
                : 'tw-text-center sgTablet:tw-text-left tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'
            "
          >
            {{ $t('dw_about_hokkien_title') }}
          </div>
          <div class="tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider">
            {{ $t('dw_about_hokkien_desc') }}
          </div>
        </div>
      </div>
    </section>

    <section
      id="hainanese-section"
      class="tw-px-4 sgTablet:tw-px-10 tw-py-5 sgTablet:tw-py-10 tw-bg-sg-dw24-blue"
    >
      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-5 sgTablet:tw-gap-10">
        <div class="sgTablet:tw-order-2 tw-flex tw-place-self-center">
          <img
            :src="$cdn('images/dw2024/about/hainanese.png')"
            class="tw-h-full tw-object-contain tw-w-[100px] sgTablet:tw-w-[200px] tw-max-w-[100px] sgTablet:tw-max-w-[200px]"
          >
        </div>
        <div class="tw-space-y-5 tw-flex-1 tw-flex tw-flex-col tw-place-self-center">
          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-center  sgTablet:tw-text-left tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold'
                : 'tw-text-center sgTablet:tw-text-left tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'
            "
          >
            {{ $t('dw_about_hainanese_title') }}
          </div>
          <div class="tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider">
            {{ $t('dw_about_hainanese_desc') }}
          </div>
        </div>
      </div>
    </section>

    <section
      id="teochew-section"
      class="tw-px-4 sgTablet:tw-px-10 tw-py-5 sgTablet:tw-py-10 tw-bg-sg-dw24-blue-b20 tw-text-white"
    >
      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-5 sgTablet:tw-gap-10">
        <div class="tw-flex tw-place-self-center">
          <img
            :src="$cdn('images/dw2024/about/teochew.png')"
            class="tw-h-full tw-object-contain tw-w-[100px] sgTablet:tw-w-[200px] tw-max-w-[100px] sgTablet:tw-max-w-[200px]"
          >
        </div>
        <div class="tw-space-y-5 tw-flex-1 tw-flex tw-flex-col tw-place-self-center">
          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-center  sgTablet:tw-text-left tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold'
                : 'tw-text-center sgTablet:tw-text-left tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'
            "
          >
            {{ $t('dw_about_teochew_title') }}
          </div>
          <div class="tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider">
            {{ $t('dw_about_teochew_desc') }}
          </div>
        </div>
      </div>
    </section>

    <section
      id="nyonya-section"
      class="tw-px-4 sgTablet:tw-px-10 tw-py-5 sgTablet:tw-py-10 tw-bg-sg-dw24-blue-b40 tw-text-white"
    >
      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-5 sgTablet:tw-gap-10">
        <div class="sgTablet:tw-order-2 tw-flex tw-place-self-center">
          <img
            :src="$cdn('images/dw2024/about/nyonya.png')"
            class="tw-h-full tw-object-contain tw-w-[100px] sgTablet:tw-w-[200px] tw-max-w-[100px] sgTablet:tw-max-w-[200px]"
          >
        </div>
        <div class="tw-space-y-5 tw-flex-1 tw-flex tw-flex-col tw-place-self-center">
          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-center  sgTablet:tw-text-left tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold'
                : 'tw-text-center sgTablet:tw-text-left tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'
            "
          >
            {{ $t('dw_about_nyonya_title') }}
          </div>
          <div class="tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider">
            {{ $t('dw_about_nyonya_desc') }}
          </div>
        </div>
      </div>
    </section>

    <section
      id="how-section"
      class="tw-relative tw-bg-sg-dw24-dw1-w60"
    >
      <img
        :src="$cdn('images/dw2024/about/deco_left.png')"
        class="tw-absolute tw-left-0 tw-top-[100px] tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
      >
      <img
        :src="$cdn('images/dw2024/about/deco_right.png')"
        class="tw-absolute tw-right-0 tw-top-[200px] tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
      >

      <img
        :src="$cdn('images/dw2024/about/deco2_left.png')"
        class="tw-absolute tw-left-0 tw-bottom-[200px] tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
      >
      <img
        :src="$cdn('images/dw2024/about/deco2_right.png')"
        class="tw-absolute tw-right-0 tw-bottom-[100px] tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
      >
      <div
        class="sgTablet:tw-max-w-[calc(100vw-200px)] sgDesktop:tw-max-w-[min(800px,calc(100vw-200px))] tw-w-full tw-mx-auto tw-px-4 tw-py-5 sgTablet:tw-px-10 sgTablet:tw-py-10 sgDesktop2:tw-px-0 tw-space-y-5 sgTablet:tw-space-y-6"
      >
        <div
          :class="
            checkLang() == 'zh-hans'
              ? 'tw-text-sg-dw24-blue-b40  tw-text-center tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold'
              : 'tw-text-sg-dw24-blue-b40  tw-text-center tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'
          "
        >
          {{ $t('dw_about_how_title') }}
        </div>
        <img
          :src="$cdn('images/dw2024/about/how.png')"
          class="tw-mx-auto tw-h-full tw-object-contain tw-w-[100px] sgTablet:tw-w-[200px]"
        >
        <div class="tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-tracking-wider">
          <p>{{ $t('dw_about_how_desc_1') }}</p>
          <br>
          <p>
            {{ $t('dw_about_how_desc_2') }}
            <span>
              <sup
                class="tw-text-[#0366D8] tw-cursor-pointer"
                @click="handleOpenImageSourceModal"
              >[2]</sup>
            </span>
            <span>
              <sup
                class="tw-text-[#0366D8] tw-cursor-pointer"
                @click="handleOpenImageSourceModal"
              >[3]</sup>
            </span>
          </p>
        </div>
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>
