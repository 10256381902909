<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <div
      class="tw-h-full tw-flex tw-justify-center tw-align-middle tw-px-4 tw-pt-5 tw-pb-[128px]
      md:tw-px-10 md:tw-pt-10 md:tw-pb-[144px]
      lg:tw-px-20"
    >
      <div class="tw-flex tw-flex-col tw-items-center">
        <h1 class="tw-text-sg-sccc1 tw-text-sg-h1-mobile md:tw-text-sg-h1-tablet lg:tw-text-sg-h1">
          {{ $t('ce_events_title') }}
        </h1>

        <div
          id="filter-scroll"
          class="tw-flex tw-py-5 md:tw-py-10 lg:tw-py-[60px] tw-overflow-x-auto tw-overflow-y-hidden"
          @touchstart="hideArrow=true"
          @touchend="hideArrow=false;updateScrollPosition()"
        >
          <div
            class="tw-relative sgTablet2:tw-w-auto md:tw-max-w-[820px] md:tw-min-w-[600px]"
          >
            <!-- Icon Left -->
            <inline-svg
              class="scroll-arrow -tw-left-1 tw-bg-white"
              :src="$cdn('icons/ce/arrow_chevron_left.svg')"
              fill="#8016B2"
              :class="{ '!tw-hidden': isReachStart || tabsScrollPosition <= 0 && !hideArrow }"
              @click="scrollTabs('left')"
            />
            <!-- Icon Right -->
            <inline-svg
              class="scroll-arrow -tw-right-1 tw-bg-white"
              :src="$cdn('icons/ce/arrow_chevron_right.svg')"
              :class="{ '!tw-hidden': isReachEnd || hideArrow}"
              @click="scrollTabs('right')"
            />
            <!-- Filter Menus -->
            <div
              class="tabs-container tw-w-[90vw] tw-flex tw-flex-row tw-justify-center tw-overflow-x-auto md:tw-pl-0 md:tw-w-auto md:tw-justify-center tw-gap-3 md:tw-gap-5"
            >
              <sg-button
                :text="$t('btn.all')"
                :is-selected="isBtn1Selected.valueOf()"
                @click="onButtonSelected(0)"
              />
              <sg-button
                :text="$t('btn.concert')"
                :is-selected="isBtn2Selected.valueOf()"
                @click="onButtonSelected(1)"
              />
              <sg-button
                :text="$t('btn.theatre')"
                :is-selected="isBtn3Selected.valueOf()"
                @click="onButtonSelected(2)"
              />
              <sg-button
                :text="$t('btn.exhibition')"
                :is-selected="isBtn4Selected.valueOf()"
                @click="onButtonSelected(3)"
              />
              <div
                v-show="checkLang() === 'zh-hans' && getScreenSize() === 'mobile'"
                class="tw-w-auto tw-h-[40px] tw-px-6 tw-py-3 tw-flex tw-flex-row"
              />
            </div>
          </div>
        </div>

        <!-- Loading -->
        <div
          v-show="isLoading"
          class="tw-w-full tw-h-full tw-relative tw-flex tw-flex-col tw-justify-center tw-max-h-[541px] tw-min-h-[421px]
          md:tw-max-h-[872px] md:tw-min-h-[700px]
          lg:tw-max-h-[478px] lg:tw-min-h-[478px]"
        >
          <div class="tw-relative tw-flex tw-justify-center">
            <div class="tw-h-[100px] tw-w-[100px]">
              <Vue3Lottie
                :animation-link="$cdn('lottiefiles/ce_loading_ren_walk_2.json')"
                loop
                :auto-play="true"
              />
            </div>
          </div>
          <p class="tw-text-sg-caption tw-text-sg-sccc1 tw-text-center tw-relative tw-bottom-[20px]">
            Please wait...
          </p>
        </div>

        <!-- Events List -->
        <div
          v-show="!isLoading"
          :class="{
            'tw-flex tw-flex-wrap tw-gap-3 md:tw-gap-5 tw-pb-5 md:tw-pb-10 lg:tw-pb-[60px] tw-justify-center tw-max-w-[820px] tw-w-full tw-mx-auto' : size === 'desktop',
            'grid': size === 'mobile' || size === 'tablet',
          }"
        >
          <div
            v-for="event in events"
            :key="event.id"
          >
            <sg-event-card
              :event="event"
            />
          </div>
        </div>

        <!-- Pagination Part -->
        <div
          v-show="!isLoading && events.length > 0 && lastPage !== 1"
          class="tw-relative tw-flex tw-flex-row tw-mt-5 md:tw-mt-10 lg:tw-mt-[60px]"
        >
          <div class="tw-pr-[6px]">
            <inline-svg
              alt="Previous Page"
              class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8"
              :class="{
                'nextIcon tw-cursor-pointer tw-rounded-full': currentPage !== 1,
              }"
              :src="currentPage === 1 ?
                $cdn('icons/pagination/keyboard_arrow_left_disable.svg') :
                $cdn('icons/pagination/keyboard_arrow_left.svg')"
              @click="onPreviousPageClick"
            />
          </div>

          <div
            v-for="number in lastPage"
            :key="number"
            :class="{
              'tw-bg-sg-sccc1 tw-text-white tw-rounded-full': number === currentPage,
              'tw-text-[#A3A3A3]': number !== currentPage,
              'hover:tw-text-sg-sccc1': number !== currentPage,
            }"
            class="tw-flex tw-items-center tw-justify-center tw-mx-[6px] tw-w-8 tw-h-8 tw-cursor-pointer
            hover:tw-border-[#8016B2] hover:tw-border-2 hover:tw-rounded-full"
          >
            <span
              @click="getEvents(number)"
            >{{ number }}
            </span>
          </div>

          <div class="tw-pl-[6px]">
            <inline-svg
              alt="Next Page"
              :src="currentPage === lastPage ?
                $cdn('icons/pagination/keyboard_arrow_right_disable.svg') :
                $cdn('icons/pagination/keyboard_arrow_right.svg')"
              :class="{
                'nextIcon tw-cursor-pointer tw-rounded-full': currentPage !== lastPage,
              } "
              class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8"
              @click="onNextPageClick"
            />
          </div>
        </div>
      </div>
    </div>
    <ce-navigation-bar />
    <ce-partner-section />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, onMounted, ref, Ref, computed } from 'vue';
import axios from 'axios';
import { PaginatedResponse, Event } from '../../../types/types';
import { checkLang, getScreenSize } from '../../../support/helper';
import { Vue3Lottie } from 'vue3-lottie';
const hideArrow = ref(false);

let isLoading = ref(false);

let isBtn1Selected = ref(true);
let isBtn2Selected = ref(false);
let isBtn3Selected = ref(false);
let isBtn4Selected = ref(false);

let currentPage = ref(1);
let lastPage = ref(1);

let events = ref<Array<Event>>([]);

let size: Ref<string| null> = ref(null);

const tabsContainer:Ref<any> = ref(null);
const tabsScrollPosition:Ref<number> = ref(0);

function onButtonSelected(index: number) {
  isBtn1Selected.value = index === 0;
  isBtn2Selected.value = index === 1;
  isBtn3Selected.value = index === 2;
  isBtn4Selected.value = index === 3;

  switch (index) {
    case 0:
      getEvents(1, null);
      break;
    case 1:
      getEvents(1, 0);
      break;
    case 2:
      getEvents(1, 1);
      break;
    case 3:
      getEvents(1, 2);
      break;
  }
}

function onNextPageClick() {
  if (currentPage.value < lastPage.value) {
    currentPage.value += 1;
    getEvents(currentPage.value);
  }
}

function onPreviousPageClick() {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
    getEvents(currentPage.value);
  }
}

function getEvents(page: number, event_type: number | null = null) {

  isLoading.value = true;

  let currentDomain = process.env.WP_SITEURL;

  if (currentDomain === undefined) {
    return;
  }

  const lang = checkLang();

  let url = currentDomain + '/wp-json/wp/v2/events?per_page=12&page=' + page + '&lang=' + lang;

  if (event_type !== null) {
    url += '&event_type=' + event_type;
  }

  axios.get(url)
    .then((response) => {
      let paginatedResponse: PaginatedResponse = response.data;
      let data: Array<any> = paginatedResponse.data;

      events.value = [];
      currentPage.value = 1;
      lastPage.value = 1;

      if(data){
        for (let event of data) {
          events.value.push({
            id: event.id,
            title: event.post_title,
            date: event.date,
            content: event.content.rendered,
            permalink: event.link,
            acf: {
              event_date: event.acf.event_date,
              event_card_date: event.acf.event_card_date,
              event_featured: event.acf.event_featured,
              event_location: event.acf.event_location,
              event_present_by: event.acf.event_present_by,
              event_ticket_price: event.acf.event_ticket_price,
              event_type: event.acf.event_type,
              event_visit_page_link: event.acf.event_visit_page_link,
              event_gallery: event.acf.event_gallery ?? [],
              event_status: event.acf.event_status,
              event_is_paid_event: event.acf.event_is_paid_event,
            }
          });
        }
        currentPage.value = paginatedResponse.current_page;
        lastPage.value = paginatedResponse.last_page;
      }

      isLoading.value = false;

    })
    .catch((error) => {
      isLoading.value = false;
      console.error(error);
    });
}

onBeforeMount(() => {
  getEvents(currentPage.value);
  window.addEventListener('resize', () => {
    size.value = getScreenSize();
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', () => {
    size.value = getScreenSize();
  });
});

onMounted(() => {
  size.value = getScreenSize();
  const getTabsContainer = document.querySelector('.tabs-container');
  tabsContainer.value = getTabsContainer;
});

const scrollTabs = (direction: string) => {
  const container = tabsContainer.value;
  const containerWidth = container.offsetWidth;
  const scrollWidth = container.scrollWidth;

  const scrollAmount = direction === 'left' ? -((scrollWidth * 2) - containerWidth) : containerWidth;
  container.scrollLeft += scrollAmount;
  updateScrollPosition();
};

const isReachEnd = computed(() => {
  let offsetWidth;
  let scrollWidth;
  if (!!tabsContainer.value){
    offsetWidth = tabsContainer.value?.offsetWidth;
    scrollWidth = tabsContainer.value?.scrollWidth;
  }

  return tabsScrollPosition.value + offsetWidth >= scrollWidth;
});

const isReachStart = computed(() => {
  return tabsScrollPosition.value <= 0;
});

const updateScrollPosition = () => {
  if (tabsContainer.value) {
    tabsScrollPosition.value = tabsContainer.value.scrollLeft * 2;
  }
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

@media (min-width: 680px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (min-width: 800px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

.scroll-arrow {
  display: none;
  position: absolute;
  cursor: pointer;
  top: -5px;
}

@media (max-width: 430px) {
  .scroll-arrow {
    display: block;
  }
}

.tabs-container {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

@media (max-width: 430px) and (min-width: 375px) {
  .tabs-container {
    justify-content: flex-start;
  }
}

.tabs-container::-webkit-scrollbar {
  width: 0; /* WebKit */
  display: none; /* Safari, Chrome, Edge */
}

.tabs-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* WebKit */
}

svg.nextIcon:hover rect {
  fill: #8016B2;
}
svg.nextIcon:hover path {
  fill: white;
}

svg.nextIcon:active rect {
  fill: #520E72;
}

svg.nextIcon:active path {
  fill: white;
}

#filter-scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
}

#filter-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
</style>
