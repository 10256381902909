<template>
  <div>
    <!--  <ap-top-nav-bar
      class="tw-z-50"
      :is-transparent="true"
    /> -->

    <!-- Header -->
    <template v-if="part==1">
      <!-- Opening hours -->
      <div
        class="tw-z-20 tw-relative tw-h-[100vh] tw-w-full tw-top-0 tw-left-0 tw-flex tw-flex-col tw-px-4 tw-py-5
    tablet:tw-pl-10 tablet:tw-pt-10 tablet:tw-pr-[50%]
    desktop:tw-pl-20 desktop:tw-pt-10 desktop:tw-pr-[50%]"
      >
        <p class="tw-mb-10 tw-text-white tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">
          {{ $t('visitor_information.opening_hours') }}
        </p>
        <p class="tw-mb-10 tw-text-white tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1">
          {{ $t('visitor_information.including_public_holidays') }}
        </p>
        <p class="tw-text-white tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3">
          {{ getCNOpeningHour('opening_mon') }}
        </p>
        <p class="tw-text-white tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3">
          {{ getCNOpeningHour('opening_tues') }}
        </p>
        <p class="tw-text-white tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3">
          {{ $t('visitor_information.free_admission') }}
        </p>

        <p
          v-if="checkNoticeToBeShow()"
          class="tw-mt-5 sgDesktop:tw-mt-10 tw-text-white tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1"
          v-html="$t('visitor_information.notices')"
        />
      </div>
      <!-- Free Guided Tours -->
      <div
        class="tw-z-20 tw-relative tw-h-full tw-w-full tw-top-0 tw-left-0 tw-bg-white tw-px-4 tw-py-5 tablet:tw-pr-10 tablet:tw-py-[60px] tablet:tw-pl-[45%]
    desktop:tw-pr-20 desktop:tw-py-[60px] desktop:tw-pl-[45%]"
      >
        <div class="tw-flex tw-flex-col">
          <p class="tw-pb-10 tw-text-gray-700 tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">
            {{ $t('visitor_information.free_guided_tours') }}
          </p>
          <p class="tw-pb-3 tw-text-gray-700 tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1">
            {{ $t('visitor_information.in_english') }}
          </p>
          <p class="tw-text-gray-700 tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3">
            {{ $t('sat_n_sun') }}
          </p>
          <p class="tw-pb-8 tw-text-gray-700 tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3">
            {{ $t('afternoon_3pm') }}
          </p>
          <p class="tw-pb-3 tw-text-gray-700 tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1">
            {{ $t('visitor_information.in_mandarin') }}
          </p>
          <p class="tw-text-gray-700 tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3">
            {{ $t('sat_n_sun') }}
          </p>
          <p class="tw-pb-10 tw-text-gray-700 tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3">
            {{ $t('afternoon_4pm') }}
          </p>
          <p class="tw-text-gray-500 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">
            {{ $t('visitor_information.free_guided_tours_desc') }}
          </p>
        </div>
      </div>

      <ap-instruction-layout-with-button
        :instruction-title="$t('gallery_make_group_event')"
        :instruction-content="$t('gallery_make_group_event_content')"
        :image-url="groupEventImage"
      />

      <!-- Location -->
      <div
        id="section-pin"
        class="headerCustom"
      >
        <div
          ref="inner"
          class="inner"
        />
        <div
          class="tw-absolute tw-flex tw-flex-col tw-inset-0 tw-justify-center tw-items-center tw-max-h-[100vh] tw-max-w-[100vw] tw-px-4
    tablet:tw-px-10
    desktop:tw-px-[25%]"
        >
          <p class="text-shadow tw-pb-10 tw-text-white tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">
            {{ $t('visitor_information.location') }}
          </p>
          <p class="text-shadow tw-pb-[60px] tw-text-center tw-text-white tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1">
            {{ $t('visitor_information.location_desc') }}
          </p>
          <!-- Button -->
          <a
            class="button hover:!tw-bg-sg-sccc5-w20"
            :href="redirectUrl()"
            target="_blank"
          >
            {{ $t('button.getting_here') }}
            <i class="tw-pl-2">
              <img
                :src="$cdn('icons/arrow_outward.svg')"
              ></i>
          </a>
        </div>
      </div>

    </template>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, toRefs, Ref } from 'vue';
import { checkLang } from '../../support/helper';
export default {
  props: {
    part:{
      type:Number,
      required:true,
    },
    aboutLocationSectionImage: {
      type: String,
      default: 'https://picsum.photos/1200?random=2',
    },
    groupEventImage: {
      type: String,
      default: 'https://picsum.photos/1200?random=2',
    },
    showNotice: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    // Refs for the inner element and the section element
    const { aboutLocationSectionImage, showNotice } = toRefs(props);
    const inner: Ref<HTMLElement | null> = ref(null);

    onMounted(() => {
      if (inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${aboutLocationSectionImage.value})`;
      }
    });

    // i18n translate so weird not translating this 2 sentence, create a manual function to translate
    const getCNOpeningHour = (key: string) => {
      const urlParams = new URLSearchParams(window.location.search);
      const langParam = urlParams.get('lang');

      if (langParam === 'zh-hans') {
        if (key == 'opening_mon') {
          return '下午2时至晚上8时 (星期一)';
        } else if (key == 'opening_tues') {
          return '上午10时至晚上8时 (星期二至星期天)';
        }
      } else {
        if (key == 'opening_mon') {
          return '2pm - 8pm (Monday)';
        } else if (key == 'opening_tues') {
          return '10am - 8pm (Tuesday to Sunday)';
        }
      }

    };

    const checkNoticeToBeShow = () => {
      return showNotice.value;
    };

    return {
      inner,
      getCNOpeningHour,
      checkNoticeToBeShow,
    };
  },
  methods: {
    redirectUrl() {
      const url = checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/visit/#accessibility' : 'https://singaporeccc.org.sg/zh-hans/visit/#accessibility' ;
      return url;
    }
  }
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.button {
  background-color: #E32726;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 8px 24px;
  cursor: pointer;
  border-radius: 26px;
  height: 52px;
  transition: opacity 0.3s ease;
}

p.text-shadow {
  text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
}

</style>
