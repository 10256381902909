<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-ap-white-smoke tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{'!tw-flex': modelValue == true}"
    >

      <!-- Modal Content -->
      <div class="tw-max-w-[343px] tw-w-full tablet:tw-max-w-[640px] tw-mx-4">
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div
            class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
            @click="handleModalClose"
          >
            <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div class="tw-bg-white tw-rounded-xl tw-w-full tw-shadow-ap-preset-1 tw-flex tw-flex-col tw-relative  tw-max-h-[80vh] tw-h-full tw-pb-5">



          <!-- Image Box -->
          <div class="tw-pt-[56.25%] tw-relative">
            <img
              class="tw-object-cover tw-h-full tw-w-full tw-object-center tw-rounded-t-xl tw-absolute tw-top-0"
              :src="data.image"
            >
          </div>

          <div class=" tw-overflow-y-auto tw-overscroll-none">
            <div class="tw-p-5 ">

              <div class="tw-text-sm tw-text-[#1A1A1A]">
                {{ programme_filter_list?.find(x => x.key === data?.category?.value)?.label }}
              </div>
              <div
                class="tw-font-[900] tw-font-hobeaux tw-mt-3 tw-text-xl tablet:tw-text-2xl"
                :class="{
                  'tw-break-keep':checkLang()=='en'
                }"
              >
                {{ data.title }}
              </div>
              <table class="tw-text-sm tw-text-[#1A1A1A] tw-border-separate tw-border-spacing-y-3">
                <tr>
                  <td class="tw-pr-2">
                    {{ $t('common.dates') }}
                  </td>
                  <td>{{ data.date }}</td>
                </tr>
                <tr>
                  <td class="tw-pr-2">
                    {{ $t('common.time') }}
                  </td>
                  <td>{{ data.time }}</td>
                </tr>
                <tr>
                  <td class="tw-pr-2">
                    {{ $t('common.venue') }}
                  </td>
                  <td>{{ data.venue }}</td>
                </tr>
              </table>


            </div>
            <ap-wysiwyg-viewer
              class="tw-p-5"
              :data=" data.description"
            />
          </div>
          <div
            v-if="data.paid"
            class="tw-absolute tw-top-3 -tw-left-2 tw-w-[111px] tw-h-[36px] tw-bg-[#D15133] tw-text-sm tw-text-white tw-font-[900] tw-flex tw-items-center tw-justify-center"
            :style="{
              boxShadow:'0px 4px 8px 0px #0000000F, 0px 0px 4px 0px #0000000A'
            }"
          >
            <div>{{ $t('cny_family_fun_paid_event') }}</div>
          </div>
        </div>

        <div
          v-if="data.paid"
          class="tw-w-full tw-flex tw-flex-row tw-justify-center tw-mt-5"
        >
          <a
            :href="data.external_link"
            target="_blank"
            class="tw-flex tw-items-center tw-justify-center tw-rounded-[26px] tw-bg-[#C52600] tw-h-[52px] tw-w-[251px] tw-text-white tw-gap-x-2"
          >
            <div>
              <div>{{ $t('cny_family_fun_get_your_tickets_here') }}</div>
            </div>
            <inline-svg :src="$cdn('icons/arrow_outward.svg')" />
          </a>
        </div>


      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { checkLang } from '../../../support/helper';
import { watch } from 'vue';
import { onMounted, computed,ref } from 'vue';
import { useI18n } from 'vue-i18n';
const {t} = useI18n();
type Post = {
  title:string,
  description:string,
  category:{
    label:string,
    value:string
  },
  date:string,
  time:string,
  venue:string,
  paid:boolean,
  image:string,
  external_link:string
}

const props = defineProps<{
  modelValue: boolean,
  data:Post
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if(!newVal) {
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  }else{
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
  }
});

const programme_filter_list = computed(()=>[
  {
    key:'all',
    label:t('ma_family_fun_category_all'),
  },
  {
    key:'activities',
    label:t('ma_family_fun_category_activities'),
  },
  {
    key:'workshops',
    label:t('ma_family_fun_category_workshops'),
  },
  {
    key:'performances',
    label:t('ma_family_fun_category_performances'),
  },
  {
    key:'online',
    label:t('ma_family_fun_category_online'),
  }
]);
</script>
