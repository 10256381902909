<template>
  <Transition name="drawer-fade">
    <div
      v-if="!modelValue"
      :class="[
        'tw-fixed tw-right-0 tw-top-[calc((100vh-142px)/2)] tw-z-[9999]'
      ]"
      @click="toggleDrawer"
    >
      <div class="tw-relative tw-inline-block tw-w-9 tw-transition-opacity tw-duration-150 tw-group/primary-navigation-handle">
        <img
          :src="$cdn('icons/ma/primary_navigation_handle_yellow.png')"
          class="tw-w-9 tw-flex  tw-cursor-pointer group-hover/primary-navigation-handle:tw-hidden tw-absolute tw-top-0 tw-left-0"
          style="filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));"
          alt="Navigation Handle Yellow"
        >
        <img
          :src="$cdn('icons/ma/primary_navigation_handle_light_yellow.png')"
          class="tw-w-9 tw-flex tw-cursor-pointer tw-absolute tw-top-0 tw-left-0"
          style="filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));"
          alt="Navigation Handle Light Yellow"
        >
      </div>
    </div>
    <div
      v-else
      :class="[
        'tw-fixed tw-right-0 tw-top-0 tw-w-[60vw] tablet:tw-w-[45vw] desktop:tw-w-[30vw] tw-h-[100vh] tw-flex tw-flex-row'
      ]"
    >
      <div
        class="tw-w-9 tw-relative"
        @click="toggleDrawer"
      >
        <img
          :src="$cdn('icons/ma/primary_navigation_handle_white.png')"
          class="tw-w-9 tw-fixed tw-top-[calc((100vh-142px)/2)] tw-cursor-pointer tw-transition-opacity tw-duration-700"
          alt="Navigation Handle"
        >
      </div>
      <div class="tw-bg-white tw-w-full tw-h-full tw-p-10 tw-py-20 tw-gap-y-8 tw-flex tw-flex-col tw-overflow-y-auto">
        <div
          v-for="(item, index) in menuItems"
          :key="index"
          :class="[
            'tw-text-sg-ma24-purple-b40 tw-text-sg-ma-sh3 tw-uppercase',
            {'!tw-text-underline': currentRoute === item.link},
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          <span
            v-if="item.isAccordian === false"
          >
            <a
              v-if="!item.isDisabled"
              :href="$langRedirect(item.link, item.isExternal ? 'external': '')"
              :target="item.isExternal ? '_blank' : '_self'"
              :style="{textDecoration: currentRoute === item.link ? 'underline' : 'inherit'}"
            >
              {{ `${$t(item.label)}` }}
            </a>
            <div
              v-else
              class="tw-text-sg-ma24-purple-b40 tw-text-opacity-25 tw-cursor-default"
            >
              {{ `${$t(item.label)}` }}
            </div>
          </span>

          <!-- Accordian Menu -->
          <div
            v-else
            class=""
          >
            <div
              class="tw-flex tw-items-center tw-flex-row tw-group tw-cursor-pointer tw-pb-1"
              :class="{'tw-underline tw-text-sg-ma24-purple-b40': accordianActive.includes(item.label)}"
              @click="handleAccordianClick(`${item.label}`)"
            >
              <div
                :class="{'tw-underline': !!item.accordian && item.accordian.map(v => v?.link).includes(currentRoute)}"
              >
                {{ `${$t(item.label)}` }}
              </div>
              <div
                v-if="item.isAccordian === true"
                class="tw-ml-2 tw-align-middle tw-flex tw-items-center"
                @click="handleAccordianClick(`${item.label}`)"
              >
                <inline-svg
                  :src="$cdn('icons/arrow_drop_down.svg')"
                  class="tw-fill-sg-ma24-purple-b40 tw-h-6 tw-w-6 tw-cursor-pointer"
                  alt="Arrow Right"
                  :class="{'-tw-rotate-90 tw-transition-transform': accordianActive.includes(item.label)}"
                  @click="handleAccordianClick(`${item.label}`)"
                />
              </div>
            </div>
            <div
              v-if="accordianActive.includes(item.label)"
              :class="[
                'tw-flex tw-flex-col tw-pt-4 tw-text-sg-ma-sh3 tw-uppercase tw-transform tw-transition-transform tw-text-sg-ma24-purple-b40 tw-gap-y-4',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              <template
                v-for="(subItem, subItem_index) of item.accordian"
                :key="subItem.label+subItem_index"
              >
                <a
                  v-if="!subItem.isDisabled"
                  class="hover:tw-underline"
                  :class="{
                    'hover:tw-no-underline tw-cursor-not-allowed tw-pointer-events-none tw-text-sg-ma24-purple-b40 tw-text-opacity-25': subItem.isDisabled,
                    'tw-underline': currentRoute === subItem.link
                  }"
                  :href="$langRedirect(subItem.link, subItem.isExternal ? 'external': '')"
                  :target="subItem.isExternal ? '_blank' : '_self'"
                >
                  {{ `${$t(subItem.label)}` }}
                </a>
                <div
                  v-else
                  class="tw-text-sg-ma24-purple-b40 tw-text-opacity-25 tw-cursor-default"
                >
                  {{ `${$t(subItem.label)}` }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted} from 'vue';
import { langRedirect } from '../../support/langRedirect';
import { checkLang } from '../../support/helper';

// const isDrawerOpen = ref<boolean>(false);
const accordianActive = ref<string>('');

const props = defineProps<{
  modelValue: boolean,
  ma2Navigation: boolean,
}>();

onMounted(() => {
  getCurrentRoute();
});

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const currentRoute = ref<string>('');

const toggleDrawer = () => {
  modelValue.value = !modelValue.value;
};

const handleAccordianClick = (content: string) => {
  if (accordianActive.value === content) {
    accordianActive.value = '';
  } else {
    accordianActive.value = content;
  }
};

const getCurrentRoute = () => {
  const url = window.location.href;
  const parts = url.replace(/^https?:\/\//, '').split('/');

  const extractedPath = '/' + parts.filter(item => item !== '' && item !== '?lang=zh-hans').slice(1).join('/');
  currentRoute.value = extractedPath;
  const accordianFound = menuItems.filter(item => item.isAccordian).filter(item => item.accordian?.map(v => v?.link).includes(extractedPath));
  if(accordianFound.length > 0){
    accordianActive.value = accordianFound[0].label;
  }
};

const menuItems = [
  {
    label: 'ma_home',
    link: '/festivals/mid-autumn',
    isAccordian: false,
    isExternal: false,

  },
  {
    label: 'ma_about',
    link: '/festivals/mid-autumn/about',
    isAccordian: false,
    isExternal: false,
    isDisabled: !props.ma2Navigation,

  },
  {
    label: 'ma_origin_stories',
    link: '/festivals/mid-autumn/origin-stories',
    isAccordian: false,
    isExternal: false,
    isDisabled: !props.ma2Navigation,
  },
  {
    label: 'ma_explore_online',
    link: '/festivals/mid-autumn/explore-online',
    isAccordian: false,
    isExternal: false,
    isDisabled: !props.ma2Navigation,
  },
  {
    label: 'ma_mid_autumn_101',
    link: '/festivals/mid-autumn/mid-autumn-101',
    isAccordian: false,
    isExternal: false,
    isDisabled: !props.ma2Navigation,
  },
  {
    label: 'ma_what_to_do',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: 'ma_family_fun',
        link: '/festivals/mid-autumn/familyfun',
        isAccordian: false,
        isExternal: false,
      },
      {
        label: 'ma_whats_open',
        link: '/festivals/mid-autumn/whats-on',
        isAccordian: false,
        isExternal: false,
        isDisabled: !props.ma2Navigation,
      },
      {
        label: 'ma_personality_quiz',
        link: '/festivals/mid-autumn/personality-quiz',
        isAccordian: false,
        isExternal: false,
        isDisabled: !props.ma2Navigation,
      },
    ],
  },
  {
    label: 'ma_past_year',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: '2023',
        link: checkLang() !== 'zh-hans' ? 'https://festivefever.singaporeccc.org.sg/mid-autumn/archival/2023/' : 'https://festivefever.singaporeccc.org.sg/mid-autumn/archival/2023/zh-hans/',
        isDisabled: false,
        isExternal: true,
      },
    ]
  },
];
</script>
