<template>
  <Transition name="drawer-fade">
    <div
      v-if="props.isDrawerOpen"
      class="tw-fixed tw-left-0 tw-top-0 tw-z-[999] sgTablet:tw-w-[100vw] sgDesktop:tw-w-[50vw] tw-w-[100vw] tw-h-[100vh] tw-max-h-[100dvh] tw-bg-white tw-shadow-md tw-p-4 sgTablet:tw-p-10 sgDesktop:tw-pb-[60px] sgDesktop:tw-px-20 tw-pt-[68px] sgTablet:tw-pt-[92px] sgDesktop:tw-pt-[128px] tw-transform -tw-translate-x-[9999px] tw-transition-transform tw-flex tw-flex-col tw-justify-between"
      :class="{ 'tw-translate-x-0': props.isDrawerOpen, 'tw-font-notosansbold': checkLang() == 'zh-hans', 'tw-font-akkuratbold ': checkLang() == 'en' }"
    >
      <!-- Tabs -->
      <!-- tw-flex tw-flex-col tw-justify-between -->
      <div v-if="!showSearchInput" class="tw-flex tw-flex-row tw-justify-between">
        <div class="tw-flex tw-flex-row tw-text-sg-body-1 tw-text-ap-nobel">
          <div v-for="(tab, index) in tabItems" :key="index" class="tw-p-2 tw-mr-4 tw-cursor-pointer">
            <div
              class="tw-uppercase tw-text-sg-sh2-mobile-main-drawer sgTablet:tw-text-sg-sh2-tablet-main-drawer sgDesktop:tw-text-sg-sh2-main-drawer tw-tracking-[0.02em]"
              :class="{ 'tw-text-sg-sccc1 tw-border-b-2 tw-border-sg-sccc1': tab.value === activeTab }"
              @click="showPanel(tab.value)"
            >
              {{ tab.label }}
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-grow tw-justify-end tw-cursor tw-items-center" @click="toggleSearchInput">
          <inline-svg :src="$cdn('icons/search_icon.svg')" class="tw-fill-ap-nobel tw-h-8 tw-w-8 tw-p-1 tw-cursor-pointer" />
        </div>
      </div>

      <!-- DRAWER MENU -->

      <template v-for="row in tabItems" :key="row.value">
        <Transition name="drawer-menu-fade">
          <!-- SINGAPOREN TAB -->
          <div
            v-if="showSearchInput == false && row.value === activeTab"
            class="tw-text-sg-sccc1-w60 tw-flex tw-flex-col tw-text-sg-h4-mobile-main-drawer sgTablet:tw-text-sg-h4-tablet-main-drawer sgDesktop:tw-text-sg-h4-main-drawer tw-tracking-[0.02em] tw-gap-y-3 sgTablet:gap-y-5 sgDesktop:tw-gap-y-4 tw-h-full tw-overflow-y-auto tw-mt-8 sgDesktop:tw-mt-7"
          >
            <div
              v-for="(item, index) in row.menu"
              :key="item.label + index"
              class="tw-flex tw-flex-row tw-items-center hover:tw-text-sg-sccc1 tw-px-2 sgDesktop:tw-px-3"
              :class="{ 'tw-text-sg-sccc1': currentRoute === item.link }"
            >
              <a
                v-if="item.isAccordian === false"
                class="tw-inline-flex tw-flex-row tw-items-center tw-cursor-pointer tw-min-h-[44px] sgDesktop:tw-min-h-[52px]"
                :href="$langRedirect(item.link, item.isExternal ? 'external' : '')"
                :target="item.isExternal ? '_blank' : '_self'"
              >
                {{ $t(`${item.label}`) }}
                <inline-svg
                  v-if="item.isExternal === true"
                  :src="$cdn('icons/top_right_arrow_icon.svg')"
                  class="tw-fill-sg-sccc1-w60 tw-h-6 tw-w-6 group-hover:tw-fill-sg-sccc1 tw-ml-4"
                  alt="Top Arrow Right"
                />
              </a>
              <!-- Accordian Menu -->
              <div v-else>
                <div
                  class="tw-min-h-[44px] sgDesktop:tw-min-h-[52px] tw-inline-flex tw-flex-row tw-group tw-cursor-pointer tw-items-center"
                  @click="handleMenuClick(`${item.label}`)"
                >
                  <div :class="{ 'tw-text-sg-sccc1 tw-border-b-2 tw-border-sg-sccc1': accordianActive.includes(item.label) }">
                    {{ $t(`${item.label}`) }}
                  </div>

                  <div class="tw-ml-2 tw-align-middle tw-flex tw-items-center">
                    <inline-svg
                      :src="$cdn('icons/arrow_right_purple.svg')"
                      class="tw-fill-sg-sccc1-w60 tw-h-5 tw-w-5 tw-pl-2 tw-ml-2 tw-mr-1 tw-pr-1 group-hover:tw-fill-sg-sccc1 tw-rotate-90 sgDesktop:tw-rotate-0"
                      alt="Arrow Right"
                      :class="{ '!-tw-rotate-90 tw-transition-transform !tw-fill-sg-sccc1 sgDesktop:!tw-rotate-0': accordianActive.includes(item.label) }"
                    />
                  </div>
                </div>
                <Transition name="accordian-fade">
                  <div
                    v-if="accordianActive.includes(item.label)"
                    class="tw-flex tw-flex-col tw-text-sg-sh1-main-drawer tw-transform tw-transition-transform tw-text-sg-sccc1-w60 tw-gap-y-3 tw-pt-3 tw-pl-2 tw-min-h-[48px] sgDesktop:tw-hidden"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    <div
                      v-for="(subItem, subItem_index) of item.accordian"
                      :key="subItem.label + subItem_index"
                      class="tw-inline-flex tw-flex-row tw-items-center"
                    >
                      <a
                        class="hover:tw-text-sg-sccc1 tw-cursor-pointer"
                        :href="$langRedirect(subItem.link, subItem.isExternal ? 'external' : '')"
                        :target="subItem.isExternal ? '_blank' : '_self'"
                      >
                        {{ $t(`${subItem.label}`) }}
                      </a>
                      <inline-svg
                        v-if="subItem.isExternal === true"
                        :src="$cdn('icons/top_right_arrow_icon.svg')"
                        class="tw-fill-sg-sccc1-w60 group-hover:tw-fill-sg-sccc1 tw-ml-4 tw-h-4 tw-w-4"
                        alt="Top Arrow Right"
                      />
                    </div>
                  </div>
                </Transition>
              </div>
            </div>

            <template v-if="row.value == 0">
              <div>
                <div
                  class="tw-rounded-full tw-w-fit tw-flex sgDesktop:tw-hidden tw-flex-row tw-items-center tw-pl-5 tw-pr-2 tw-text-sg-h4-mobile-main-drawer sgTablet:tw-text-sg-h4-tablet-main-drawer sgDesktop:tw-text-sg-h4-main-drawer tw-text-ap-white-smoke tw-cursor-pointer tw-bg-sg-sccc1-w40 tw-min-h-[48px] sgDesktop:tw-min-h-[56px]"
                  :class="{
                    '!tw-bg-sg-sccc1': accordianActive.includes('experience'),
                  }"
                  @click="handleMenuClick('experience')"
                >
                  {{ $t('experience') }}
                  <inline-svg
                    :src="$cdn('/icons/arrow_right_purple.svg')"
                    class="tw-fill-ap-white tw-h-5 tw-w-5 tw-pl-2 tw-ml-2 tw-mr-1 tw-pr-1 tw-rotate-90 sgDesktop:tw-rotate-0"
                    :class="{ '!-tw-rotate-90 tw-transition-transform sgDesktop:!tw-rotate-0': accordianActive.includes('experience') }"
                    alt="Arrow Right"
                  />
                </div>
                <div
                  v-if="accordianActive.includes('experience')"
                  class="tw-flex sgDesktop:tw-hidden tw-flex-col tw-text-sg-sh1-main-drawer tw-transform tw-transition-transform tw-text-sg-sccc1-w60 tw-gap-y-3 tw-pt-3"
                >
                  <div
                    v-for="(subItem, subItem_index) of experienceSubMenu"
                    :key="subItem.label + subItem_index"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    class="tw-flex tw-flex-row tw-align-middle hover:tw-text-sg-sccc1 tw-cursor-pointer tw-text-sg-sh1-main-drawer tw-min-h-[40px] tw-pl-2"
                  >
                    <a
                      v-if="subItem.isAccordian === false"
                      class="tw-inline-flex tw-flex-row tw-items-center"
                      :href="$langRedirect(subItem.link, subItem.isExternal ? 'external' : '')"
                      :target="subItem.isExternal ? '_blank' : '_self'"
                    >
                      {{ $t(`${subItem.label}`) }}
                      <inline-svg
                        v-if="subItem.isExternal === true"
                        :src="$cdn('icons/top_right_arrow_icon.svg')"
                        class="tw-fill-sg-sccc1-w60 group-hover:tw-fill-sg-sccc1 tw-ml-4 tw-h-4 tw-w-4"
                        alt="Top Arrow Right"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </Transition>
      </template>

      <!-- SEARCH PANEL -->

      <!-- Search Input -->
      <Transition name="drawer-menu-fade">
        <div v-if="showSearchInput" class="tw-h-full tw-flex tw-flex-col">
          <div class="tw-flex tw-flex-row tw-transition-transform tw-duration-700 tw-items-center tw-gap-x-1">
            <inline-svg
              :src="$cdn('icons/search_icon.svg')"
              alt="Arrow Right"
              class="tw-fill-sg-sccc1 tw-h-8 tw-w-8 tw-p-1 tw-cursor-pointer"
              @click="toggleSearchInput"
            />
            <div
              class="tw-flex tw-w-full tw-flex-row tw-border-b tw-border-sg-sccc1 tw-outline-none tw-ring-0 focus:tw-ring-0 tw-border-0 focus:tw-border-b-2 focus:tw-border-sg-sccc1"
            >
              <input
                v-model="searchInputValue"
                type="text"
                :placeholder="$t('type_here')"
                class="tw-border-b-2 tw-border-sg-sccc1 tw-outline-none tw-ring-0 focus:tw-ring-0 tw-border-0 tw-peer focus:tw-border-b-2 focus:tw-border-sg-sccc1 tw-px-2 tw-py-1 tw-flex tw-flex-grow tw-text-sg-sccc1"
                @input="searchArticles"
              />
              <div
                v-if="searchInputValue !== ''"
                class="tw-text-sg-label-4 tw-text-[#A3A3A3] tw-cursor-pointer hover:tw-font-bold hover:tw-text-sg-sccc1 tw-border-sg-sccc1 tw-border-b-2 peer-focus:tw-border-b-2 tw-flex tw-flex-col tw-justify-end tw-p-1"
                @click="clearSearchValue"
              >
                {{ $t('clear') }}
              </div>
            </div>
          </div>

          <!-- Search Panel -->

          <Transition name="search-slide-fade" tag="div" class="tw-flex-1 tw-my-10">
            <!-- Search Card -->
            <div v-if="searchResults.length > 0" class="tw-h-full tw-overflow-y-auto">
              <template v-for="(searchItem, searchIndex) in searchResults" :key="`search_${searchIndex}`">
                <div
                  class="tw-flex tw-flex-col tw-group tw-cursor-pointer tw-p-3 tw-gap-y-3 sgDesktop:tw-p-4 sgDesktop:tw-gap-y-4"
                  @click="handleRedirectToArticles(searchItem.link)"
                >
                  <div
                    class="tw-uppercase tw-text-sg-sccc1 tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3"
                    v-html="searchItem?.title"
                  />
                  <div
                    class="tw-w-full tw-line-clamp-3 sgTablet:tw-line-clamp-4 mobile:tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
                  >
                    {{ removeHtmlString(searchItem.content ?? '') }}
                  </div>
                  <div class="tw-flex tw-text-sg-sccc1 tw-flex-row-reverse tw-text-xs">
                    <div class="group-hover:tw-underline tw-cursor-pointer">MORE ></div>
                  </div>
                </div>

                <hr v-if="searchIndex < searchResults.length - 1" class="tw-h-[2px] tw-bg-[#D1D1D1] tw-my-2" />
              </template>
            </div>
            <div
              v-else
              class="tw-w-full tw-h-full tw-items-center tw-justify-center tw-flex tw-text-ap-nobel tw-text-sg-sh2-mobile tw-italic sgTablet:tw-text-sg-sh2-tablet sgDesktop:tw-text-sg-sh2"
            >
              <p>{{ searchLoading ? $t('searching') : t('no_suggestion') }}</p>
            </div>
          </Transition>

          <div class="tw-flex">
            <div class="tw-flex tw-items-center tw-text-sg-sccc1 tw-text-sm tw-font-bold tw-cursor-pointer tw-py-2 tw-px-3" @click="showSearchInput = false">
              <inline-svg class="tw-fill-sg-sccc1 tw-h-5 tw-w-5 tw-p-1 tw-mr-2" :src="$cdn('icons/arrow_chevron_left.svg')" alt="Arrow Left" />
              {{ $t('back') }}
            </div>
          </div>
        </div>
      </Transition>

      <!-- Tab Panel Panel -->
      <div v-if="showSearchInput == false" class="tw-flex tw-flex-col">
        <!-- Experience Chip -->
        <div
          class="tw-bg-sg-sccc1-w40 tw-rounded-full tw-items-center tw-w-fit tw-hidden sgDesktop:tw-flex tw-flex-row tw-py-[5px] tw-pl-[24px] tw-pr-3 tw-text-sg-h4-mobile sgTablet:tw-text-sg-h4-tablet sgDesktop:tw-text-sg-h4 tw-text-ap-white-smoke tw-cursor-pointer tw-min-h-[48px] sgDesktop:tw-min-h-[56px] !tw-font-normal"
          :class="{
            '!tw-bg-sg-sccc1': accordianActive.includes('experience'),
            'tw-font-notosansbold': checkLang() == 'zh-hans',
            'tw-font-akkuratbold ': checkLang() == 'en',
          }"
          @click="handleMenuClick('experience')"
        >
          {{ $t('experience') }}
          <inline-svg :src="$cdn('/icons/arrow_right_purple.svg')" class="tw-fill-ap-white tw-h-5 tw-w-5 tw-pl-2 tw-ml-4 tw-mr-1 tw-pr-1" alt="Arrow Right" />
        </div>

        <div class="tw-mt-8 sgDesktop:tw-mt-7">
          <!-- Language Toggle -->
          <div class="tw-relative tw-inline-flex tw-cursor tw-items-center">
            <span
              class="tw-me-3 tw-text-sm tw-text-ap-nobel"
              :class="{
                '!tw-text-sg-sccc1 tw-font-akkuratbold ': checkLang() == 'en',
                'tw-font-notosans ': checkLang() == 'zh-hans',
              }"
              >EN</span
            >

            <label class="tw-relative tw-inline-flex tw-items-center" @click="changeLanguage()">
              <input type="checkbox" :checked="currentLang === 'zh-hans'" class="sr-only tw-peer tw-hidden" />
              <div
                class="tw-w-8 tw-h-4 peer-focus:tw-outline-none peer-focus:tw-ring-2 peer-focus:tw-ring-sg-sccc1-w40 tw-rounded-full tw-peer tw-peer peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-[2px] after:tw-start-[4px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-3 after:tw-w-3 after:tw-transition-all tw-bg-sg-sccc1"
              />
            </label>
            <span
              class="tw-ms-3 tw-text-sm tw-text-ap-nobel"
              :class="{
                '!tw-text-sg-sccc1 tw-font-bold': checkLang() == 'zh-hans',
              }"
              >中文</span
            >
          </div>
        </div>
      </div>

      <!-- Siderbar sub-drawer -->
      <Transition name="drawer-fade">
        <div
          v-if="!!subDrawerData && subDrawerData.length > 0"
          class="tw-hidden sgDesktop:tw-block tw-absolute tw-left-[50vw] tw-top-0 -tw-z-10 tw-min-w-[30vw] tw-w-min tw-h-[100vh] tw-max-h-[100dvh] tw-bg-ap-white tw-border-l-4 tw-p-8 tw-pt-[202px] tw-border-sg-sccc1 -tw-translate-x-[999vw] -tw-z-1 tw-shadow-md tw-transform tw-ease-ap-preset-1 tw-duration-100"
          :class="{ 'tw-translate-x-0': !!subDrawerData && subDrawerData.length > 0 }"
        >
          <div class="tw-text-sg-sccc1-w60 tw-flex tw-flex-col tw-text-sg-sh1-main-drawer tw-gap-y-3">
            <div
              v-for="(item, index) in subDrawerData"
              :key="item.label + index"
              class="tw-flex tw-flex-row tw-align-middle hover:tw-text-sg-sccc1 tw-min-h-[48px] tw-z-[10]"
            >
              <a
                v-if="item.isAccordian === false"
                class="tw-inline-flex tw-flex-row tw-items-center tw-cursor-pointer !tw-font-normal"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold ' : 'tw-font-akkuratbold'"
                :href="$langRedirect(item.link, item.isExternal ? 'external' : '')"
                :target="item.isExternal ? '_blank' : '_self'"
              >
                {{ $t(`${item.label}`) }}
                <inline-svg
                  v-if="item.isExternal === true"
                  :src="$cdn('icons/top_right_arrow_icon.svg')"
                  class="tw-fill-sg-sccc1-w60 tw-h-6 tw-w-6 group-hover:tw-fill-sg-sccc1 tw-ml-4 tw-rotate-90 sgDesktop:tw-rotate-0"
                  alt="Top Arrow Right"
                  :class="{ '!-tw-rotate-90 tw-transition-transform sgDesktop:!tw-rotate-0': accordianActive.includes(item.label) }"
                />
              </a>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, onBeforeMount } from 'vue';
import { getSgScreenSize } from '../../support/helper';
import { removeHtmlString } from '../../support/helper';
import { arraysAreEqual } from '../../support/helper';
import { useI18n } from 'vue-i18n';
import { langRedirect } from '../../support/langRedirect';
import { checkLang } from '../../support/helper';

type NavItem = {
  label: string;
  link: string;
  isAccordian: boolean;
  isExternal: boolean;
  accordian?: NavItem[];
};

const tabItems = ref<{ label: string; value: number; menu: NavItem[] }[]>([]);
const subDrawerData = ref<any[]>([]);

const activeTab = ref(0);
const showSearchInput = ref(false);
const searchInputValue = ref('');
const searchLoading = ref(false);
const searchResults = ref([]);
const accordianActive = ref<string>('');
const currentRoute = ref<string>('');
const currentLang = ref<string>('en');

const { t, locale } = useI18n({ useScope: 'global' });

// Init before data ready
const getLanguage = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  currentLang.value = langParam ? langParam : 'en';

  if (langParam == 'zh-hans') {
    locale.value = 'zh-CN';
  }
};

getLanguage();

const props = defineProps<{
  isDrawerOpen: boolean;
  pageUrl: string;
}>();
onBeforeMount(() => {
  tabItems.value = [
    { label: 'singapo人', value: 0, menu: singaporenMenu },
    { label: 'sccc', value: 1, menu: scccMenu },
  ];
});

onMounted(() => {
  getCurrentRoute();
});

watch(
  () => props.isDrawerOpen,
  () => {
    if (!props.isDrawerOpen) {
      accordianActive.value = '';
      subDrawerData.value = [];
      showSearchInput.value = false;
      if (window.innerWidth <= 680) {
        //If is mobile
        document.body.style.overflow = '';
      }
    } else {
      if (window.innerWidth <= 680) {
        //If is mobile
        document.body.style.overflow = 'hidden';
      }
    }
  }
);

const emits = defineEmits(['toggle-drawer']);
// console.log(t('deep_dive_into_roots'),'check root');
const singaporenMenu: NavItem[] = [
  {
    label: 'roots',
    link: '/roots',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'values',
    link: '/values',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'food',
    link: '/food',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'festivals',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: 'overview',
        link: '/festivals',
        isAccordian: false,
        isExternal: false,
      },
      {
        label: 'chinese_new_year',
        link: '/festivals/chinese-new-year',
        isAccordian: false,
        isExternal: false,
      },
      {
        label: 'qingming',
        link: '/festivals/qingming',
        isAccordian: false,
        isExternal: false,
      },
      {
        label: 'duanwu',
        link: '/festivals/duanwu',
        isAccordian: false,
        isExternal: false,
      },
      ...(window?.__wordpress_data__?.canView?.includes('sidebar.zy')
        ? [
            {
              label: 'zhongyuan',
              link: '/festivals/zhongyuan',
              isAccordian: false,
              isExternal: false,
            },
          ]
        : []),
      ...(window?.__wordpress_data__?.canView?.includes('sidebar.ma')
        ? [
            {
              label: 'midautumn',
              link: '/festivals/mid-autumn',
              isAccordian: false,
              isExternal: false,
            },
          ]
        : []),
    ],
  },
  {
    label: 'practices',
    link: '/practices',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'language',
    link: '/language',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'arts',
    link: '/arts',
    isAccordian: false,
    isExternal: false,
  },
  ...(window?.__wordpress_data__?.canView?.includes('sidebar.culturepaedia')
    ? [
        {
          label: 'resources',
          link: '',
          isAccordian: true,
          isExternal: false,
          accordian: [
            {
              label: 'culturepaedia',
              link: checkLang() !== 'zh-hans' ? 'https://culturepaedia.singaporeccc.org.sg/' : 'https://culturepaedia.singaporeccc.org.sg/zh/',
              isAccordian: false,
              isExternal: true,
            },
          ],
        },
      ]
    : []),
];

const scccMenu: NavItem[] = [
  {
    label: 'sccc_website',
    link: checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/' : 'https://singaporeccc.org.sg/zh-hans/',
    isAccordian: false,
    isExternal: true,
  },
  ...(window?.__wordpress_data__?.canView?.includes('sidebar.culturepaedia')
    ? [
        {
          label: 'culturepaedia',
          link: checkLang() !== 'zh-hans' ? 'https://culturepaedia.singaporeccc.org.sg/' : 'https://culturepaedia.singaporeccc.org.sg/zh/',
          isAccordian: false,
          isExternal: true,
        },
      ]
    : []),
  {
    label: 'contact_us',
    link: checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/contact/' : 'https://singaporeccc.org.sg/zh-hans/contact/',
    isAccordian: false,
    isExternal: true,
  },
  {
    label: 'visit',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: 'visit_sccc',
        link: checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/visit/' : 'https://singaporeccc.org.sg/zh-hans/visit/',
        isAccordian: false,
        isExternal: true,
      },
      {
        label: 'contact_us',
        link: 'mailto:enquiries@singaporeccc.org.sg',
        isAccordian: false,
        isExternal: true,
      },
    ],
  },
];

const festivalSubMenu: NavItem[] = [
  {
    label: 'overview',
    link: '/festivals',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'chinese_new_year',
    link: '/festivals/chinese-new-year',
    isAccordian: false,
    isExternal: false,
  },

  {
    label: 'qingming',
    link: '/festivals/qingming',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'duanwu',
    link: '/festivals/duanwu',
    isAccordian: false,
    isExternal: false,
  },
  ...(window?.__wordpress_data__?.canView?.includes('sidebar.zy')
    ? [
        {
          label: 'zhongyuan',
          link: '/festivals/zhongyuan',
          isAccordian: false,
          isExternal: false,
        },
      ]
    : []),
  ...(window?.__wordpress_data__?.canView?.includes('sidebar.ma')
    ? [
        {
          label: 'midautumn',
          link: '/festivals/mid-autumn',
          isAccordian: false,
          isExternal: false,
        },
      ]
    : []),
];

const resourcesSubMenu: NavItem[] = [
  {
    label: 'culturepaedia',
    link: checkLang() !== 'zh-hans' ? 'https://culturepaedia.singaporeccc.org.sg/' : 'https://culturepaedia.singaporeccc.org.sg/zh/',
    isAccordian: false,
    isExternal: true,
  },
];

const experienceSubMenu: NavItem[] = [
  {
    label: 'on_site_exhibition',
    link: '/exhibition/about',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'online_exhibition',
    link: checkLang() !== 'zh-hans' ? 'https://singaporen.singaporeccc.org.sg/' : 'https://singaporen.singaporeccc.org.sg/homepage?lang=cn',
    isAccordian: false,
    isExternal: true,
  },
  {
    label: 'cultural_extravaganza',
    link: '/experience/ce/about',
    isAccordian: false,
    isExternal: false,
  },
  // {
  //   label: 'remix',
  //   link: '#',
  //   isAccordian: false,
  //   isExternal: false,
  // },
];

const visitSubMenu: NavItem[] = [
  {
    label: 'visit_sccc',
    link: checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/visit/' : 'https://singaporeccc.org.sg/zh-hans/visit/',
    isAccordian: false,
    isExternal: true,
  },
  {
    label: 'contact_us',
    link: 'mailto:enquiries@singaporeccc.org.sg',
    isAccordian: false,
    isExternal: true,
  },
];

const getCurrentRoute = () => {
  const url = props.pageUrl;
  const parts = url.replace(/^https?:\/\//, '').split('/');

  const extractedPath = '/' + parts.slice(1, 2).join('/');
  currentRoute.value = extractedPath;
};

const emitToggleDrawer = () => {
  emits('toggle-drawer', false);
};

const handleTabChange = () => {};

const showPanel = (panelIndex: number) => {
  activeTab.value = panelIndex;
};

const toggleSearchInput = () => {
  showSearchInput.value = !showSearchInput.value;
};

const handleMenuClick = (content: string, external?: string) => {
  if (accordianActive.value === content) {
    accordianActive.value = '';
  } else {
    accordianActive.value = content;
  }
  toggleSubdrawer(content);
  if (getSgScreenSize() === 'desktop') {
  } else {
  }
};

const toggleSubdrawer = (content: string) => {
  if (content.toLocaleLowerCase() == 'experience') {
    if (!arraysAreEqual(subDrawerData.value, experienceSubMenu)) {
      subDrawerData.value = experienceSubMenu;
    } else if (arraysAreEqual(subDrawerData.value, experienceSubMenu)) {
      subDrawerData.value = [];
    }
  } else if (content.toLocaleLowerCase() == 'festivals') {
    if (!arraysAreEqual(subDrawerData.value, festivalSubMenu)) {
      subDrawerData.value = festivalSubMenu;
    } else if (arraysAreEqual(subDrawerData.value, festivalSubMenu)) {
      subDrawerData.value = [];
    }
  } else if (content.toLocaleLowerCase() == 'resources') {
    if (!arraysAreEqual(subDrawerData.value, resourcesSubMenu)) {
      subDrawerData.value = resourcesSubMenu;
    } else if (arraysAreEqual(subDrawerData.value, resourcesSubMenu)) {
      subDrawerData.value = [];
    }
  } else if (content.toLocaleLowerCase() == 'visit') {
    if (!arraysAreEqual(subDrawerData.value, visitSubMenu)) {
      subDrawerData.value = visitSubMenu;
    } else if (arraysAreEqual(subDrawerData.value, visitSubMenu)) {
      subDrawerData.value = [];
    }
  }
};

const handleExternalClick = () => {
  window.location.href = '/';
};

const fetchContent = async (type: string, ids: string) => {
  if (!ids) return [];
  return fetch(
    `${process.env.APP_URL}/wp-json/wp/v2/${type === 'post' ? 'posts' : 'pages'}?${new URLSearchParams({
      include: ids,
    })}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data.map((row) => {
        return {
          id: row?.id,
          link: row?.link,
          title: row?.title?.rendered,
          content: row?.content?.rendered,
        };
      });
    })
    .catch((error) => {
      console.error(`Error fetching ${type} content:`, error);
      return '';
    });
};

const searchArticles = debounce(async () => {
  searchResults.value = [];
  if (!searchInputValue.value.trim()) {
    return;
  }

  try {
    searchLoading.value = true;
    let resData: any = [];
    // const api_url = `${process.env.APP_URL}/wp-json/wp/v2/posts?search=${searchInputValue.value}`;
    const api_url = `${process.env.APP_URL}/wp-json/wp/v2/search?search=${searchInputValue.value}`;
    const response = await fetch(api_url)
      .then((response) => response.json())
      .then(async (data) => {
        if (data && data.length > 0) {
          let postIds = data
            .filter((r) => r.subtype === 'post')
            .map((r) => r.id)
            .join(',');
          let pageIds = data
            .filter((r) => r.subtype === 'page')
            .map((r) => r.id)
            .join(',');
          const posts = await fetchContent('post', postIds);
          const pages = await fetchContent('page', pageIds);
          pages.forEach((page) => {
            let index = data.findIndex((r) => r.id === page.id && r.subtype === 'page');
            if (index != -1) {
              data[index] = page;
            }
          });
          posts.forEach((post) => {
            let index = data.findIndex((r) => r.id === post.id && r.subtype === 'post');
            if (index != -1) {
              data[index] = post;
            }
          });

          searchResults.value = data;
        }
      });
  } catch (error) {
    console.log('Error searching articles:', error);
  } finally {
    searchLoading.value = false;
  }
}, 500);

const handleRedirectToArticles = (url: string) => {
  window.location.href = langRedirect(url);
};

const clearSearchValue = () => {
  searchInputValue.value = '';
  searchResults.value = [];
};

const changeLanguage = async () => {
  const currentURL = window.location.href;
  let lang;
  if (currentLang.value === 'zh-hans') {
    lang = 'en';
    // window.icl_change_language('en');
  } else if (currentLang.value === 'en') {
    lang = 'zh-hans';
    // i18_local = 'zhCN';
    // window.icl_change_language('zh-hans');
  }

  const langRegex = /(\?|&)lang=[^&]*/;
  const hasLangParam = langRegex.test(currentURL);

  const newUrl = hasLangParam ? currentURL.replace(langRegex, `$1lang=${lang}`) : currentURL + (currentURL.includes('?') ? '&' : '?') + `lang=${lang}`;

  window.location.href = newUrl;
};
function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout;

  return function (...args: Parameters<T>): void {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

watch(
  () => props.isDrawerOpen,
  (newVal) => {
    if (newVal) {
      // Not fixing the backgroudn scroll first
      // document.body.classList.add('tw-overflow-hidden');
    } else {
      // document.body.classList.remove('tw-overflow-hidden');
    }
  }
);
</script>

<style>
.sidebar {
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-250px);
}

.sidebar-open {
  transform: translateX(0);
}

.tabs {
  display: flex;
  cursor: pointer;
}

.tab {
  padding: 10px;
  margin-right: 10px;
  background-color: #cd1b1b;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
}

.tab:hover {
  background-color: #e0e0e0;
}
</style>
